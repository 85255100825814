<template>
  <div id="Login">
    <div class="bg" :style="{ backgroundImage: 'url(' + require('@/assets/image/bg.jpg') + ')' }"></div>
    <section>
      <ul id="btnArea">
        <li @click="mode = 'login'" :class="{ active: mode == 'login' }">Login</li>
        /
        <li @click="mode = 'signup'" :class="{ active: mode == 'signup' }">Sign up</li>
      </ul>
      <div class="form login" v-if="mode == 'login'">
        <ul>
          <li>
            <f-icon icon="id-badge" type="fas"/>
            <input type="text" placeholder="ID" v-model="loginData.user_id" />
          </li>
          <li>
            <f-icon icon="key" type="fas"/>
            <input type="password" placeholder="Password" v-model="loginData.user_pwd" />
          </li>
        </ul>
        <button @click="loginCheck">Login</button>
      </div>
      <div class="form signup" v-if="mode == 'signup'">
        <ul>
          <li>
            <f-icon icon="id-badge"/>
            <input type="text" placeholder="ID" v-model="signupData.user_id" class="withBtn" />
            <button @click="checkID">check</button>
          </li>
          <li>
            <f-icon icon="user" type="far"/>
            <input type="text" placeholder="Name" v-model="signupData.user_name" />
          </li>
          <li>
            <f-icon icon="key" type="fas"/>
            <input type="password" placeholder="Password" v-model="signupData.user_pwd" @change="checkPwd" />
          </li>
          <li>
            <f-icon icon="key" type="fas"/>
            <input type="password" placeholder="Password Check" v-model="signupData.pwd_chk" @change="checkPwd" />
          </li>
        </ul>
        <button @click="signup">Sign up</button>
      </div>
    </section>
  </div>
</template>

<script>
import APIUtils from "@/utils/Network/APIUtils.js";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      mode: "login",
      loginData: {
        user_id: "",
        user_pwd: "",
      },
      signupData: {
        user_id: "",
        user_name: "",
        user_pwd: "",
        pwd_chk: "",
      },
      check: {
        id: false,
        pw: false,
      },
    };
  },
  methods: {
    loginCheck() {
      if (this.loginData.user_id == "") alert("아이디를 입력해주세요.");
      else if (this.loginData.user_pwd == "") alert("비밀번호를 입력해주세요.");
      else {
        this.login(this.loginData);
      }
    },
    checkID() {
      APIUtils.user_checkId(this.signupData).then((res) => {
        if (res.body) {
          alert("이미 사용중인 아이디 입니다.");
          this.check.id = false;
        } else {
          alert("사용 가능한 아이디입니다.");
          this.check.id = true;
        }
      });
    },
    checkPwd() {
      if (this.signupData.user_pwd != this.signupData.pwd_chk) {
        this.check.pw = false;
      } else {
        this.check.pw = true;
      }
    },
    signup() {
      if (this.check.id && this.check.pw) {
        APIUtils.user_insert(this.signupData).then((res) => {
          if (!res.resultCode) {
            this.signupData = {
              user_id: "",
              user_name: "",
              user_pwd: "",
              pwd_chk: "",
            };
            this.mode = "login";
          }
        });
      } else if (!this.check.id) {
        alert("아이디 중복체크를 진행해주세요.");
      } else if (!this.check.pw) {
        alert("비밀번호가 일치하지 않습니다.\n다시 확인 후 진행해주세요.");
      }
    },
    ...mapActions(["login"]),
  },
};
</script>

<style scoped>
#Login {
  position: relative;
  width: 100%;
  height: 100%;
}
.bg {
  position: relative;
  width: 100%;
  height: 100%;
  background-size: cover;
  filter: blur(200px);
}
section {
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  border-radius: 2px;
  padding: 32px;
  box-shadow: 0px 0px 20px #000;
}
#btnArea {
  margin-bottom: 30px;
}
#btnArea li {
  display: inline-block;
  color: #888;
  font-size: 24px;
  cursor: pointer;
}
#btnArea li.active {
  color: #000;
}
.form {
  position: relative;
  height: 300px;
  padding-bottom: 50px;
}
.form ul {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: calc(100% - 30px);
}
.form li {
  height: 30px;
  width: 320px;
  border-bottom: 2px solid var(--color-sub);
  padding-left: 30px;
  position: relative;
}
.form li > *:nth-child(1) {
  position: absolute;
  height: 16px;
  width: 16px;
  left: 7px;
  top: 50%;
  transform: translateY(-50%);
  color: var(--color-sub);
}
.form li > button {
  height: 100%;
  border: 0;
  background: transparent;
  float: right;
}
.form input {
  height: 100%;
  border: 0;
  background: transparent;
  width: 100%;
  padding: 0 8px;
  font-size: 14px;
}
.form input.withBtn {
  height: 100%;
  width: calc(100% - 40px);
}
.form > button {
  width: 160px;
  height: 30px;
  background: transparent;
  border: 1px solid var(--color-sub);
  color: var(--color-sub);
  border-radius: 10px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
</style>
